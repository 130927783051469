import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'

import bootstrapService from '../../../services/BootstrapService'
import paymentService from '../../../services/PaymentService'

class IndividualCourse extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // This Binding
        this.goToCourse = this.goToCourse.bind(this);
        this.goToBuy = this.goToBuy.bind(this);
    }

    // Event Handlers
    goToCourse(e) {
        if (window.innerWidth < 768) {
            this.props.history.push(`/course/${this.props.data.id}`);
        }
    }

    goToBuy(e) {
        if (this.props.data.item.price) {
            paymentService.reset();
            paymentService.item = { ...this.props.data.item, quantity: 1 };
            this.props.history.push("/buy");
        }
        else {
            this.props.history.push({ pathname: "/", state: { tryForFree: true } });
        }
    }

    // Component
    render() {
        const courseId = this.props.data.id;
        const title = this.props.data.title;
        const description = this.props.data.description;
        const shortDescription = description.substring(0, 280).split(" ").slice(0, -1).join(" ") + "...";
        const category = this.props.data.subjectArea;
        const credits = this.props.data.credits;
        const courseNo = this.props.data.courseNo;
        const price = this.props.data.item.price;

        const categoryTerm = bootstrapService.getCategoryTerm();
        const creditsTerm = bootstrapService.getCreditsTerm(credits);

        return (
            <li className="a4-plan-container -individual-course" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                <div className="a4-plan-item">
                    <div className="a4-plan-section">
                        <h3 className="a4-plan-title -individual-course" onClick={this.goToCourse}>{title}</h3>
                        <p className="a4-plan-description -individual-course" onClick={this.goToCourse}>{shortDescription}</p>
                        <ul className="a4-plan-advantages -individual-course">
                            <li className="a4-plan-advantages-item -individual-course">
                                <mark className="a4-plan-advantages-item-title"><span className="text-primary">{categoryTerm}:</span></mark>
                                <span className="a4-plan-advantages-item-value">{category}</span>
                            </li>
                            <li className="a4-plan-advantages-item -individual-course">
                                <mark className="a4-plan-advantages-item-title"><span className="text-primary">{creditsTerm}:</span></mark>
                                <span className="a4-plan-advantages-item-value">{credits.toFixed(1)}</span>
                            </li>
                            <li className="a4-plan-advantages-item -individual-course">
                                <mark className="a4-plan-advantages-item-title"><span className="text-primary">Course No:</span></mark>
                                <span className="a4-plan-advantages-item-value">{courseNo}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="a4-plan-section">
                        <div className="a4-plan-price -individual-course">
                            <b className="a4-plan-price-digits">
                                <span className="a4-plan-price-int">${String(price).split('.')[0]}</span>
                                <span className="a4-plan-price-float">{String(price).split('.')[1]}</span>
                            </b>
                        </div>
                        <div className="a4-plan-controls">
                            <BubbleButton onClick={this.goToBuy} className="a4-plan-buy-now -primary">{price ? 'Buy' : 'Take'} Now</BubbleButton>
                            <Link to={`/course/${courseId}`} className="a4-plan-learn-more -individual-course">About Course</Link>
                        </div>
                    </div>
                </div>
            </li>

        )
    }
}

export default withRouter(IndividualCourse);