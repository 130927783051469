import React from 'react'
import './Calendar.scss'

import { ReactComponent as PreviousButton } from '../../../assets/less-than.svg'
import { ReactComponent as NextButton } from '../../../assets/greater-than.svg'
import { ReactComponent as Clock } from '../../../assets/clock.svg'
import bootstrapService from '../../../services/BootstrapService'
import scheduleService from '../../../services/ScheduleService'

class Calendar extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // State
        this.state = {
            currentSchedule: this.props.schedules[0],
            currentMonth: scheduleService.getMonth(this.props.schedules[0].startDate),
            currentYear: scheduleService.getYear(this.props.schedules[0].startDate),
            days: [],
        }

        // This Binding
        this.clickPreviousMonth = this.clickPreviousMonth.bind(this);
        this.clickNextMonth = this.clickNextMonth.bind(this);
        this.clickDay = this.clickDay.bind(this);
    }

    // Lifecyle
    componentDidMount() {
        this.setupSchedules();
    }

    // Helpers
    setupSchedules() {
        const days = this.getDays(scheduleService.getMonth(this.state.currentSchedule.startDate), scheduleService.getYear(this.state.currentSchedule.startDate), this.state.currentSchedule);
        this.setState({ days: days }, this.props.selectSchedule(this.state.currentSchedule));
    }

    getDays(month, year, currentSchedule) {
        const firstDayOfWeek = scheduleService.getFirstDayOfWeek(month, year);
        const lastDayOfWeek = scheduleService.getLastDayOfWeek(month, year);
        const daysInMonth = scheduleService.getDaysInMonth(month, year);
        const daysinPreviousMonth = scheduleService.getDaysInPreviousMonth(month, year);

        let days = [];

        // Add Previous Month's Days
        for (let i = daysinPreviousMonth - firstDayOfWeek + 1; i <= daysinPreviousMonth; i++) {
            days.push({ "day": i, "disabled": true, "unselected": false, "selected": false })
        }

        // Add This Month's Days
        for (let i = 1; i <= daysInMonth; i++) {

            let unselected = false;
            let selected = false;

            this.props.schedules.filter(s => scheduleService.getMonth(s.startDate) === month && scheduleService.getYear(s.startDate) === year).forEach((schedule, index) => {
                const day = scheduleService.getDay(schedule.startDate);
                if (day === i) {
                    unselected = true;

                    if (schedule.startDate === currentSchedule.startDate) {
                        selected = true;
                    }
                }
            })

            days.push({ "day": i, "disabled": false, "unselected": unselected, "selected": selected })
        }

        // Add Next Month's Days
        for (let i = 1; i <= 6-lastDayOfWeek; i++) {
            days.push({ "day": i, "disabled": true, "unselected": false, "selected": false });
        }

        return days;
    }

    // Event Handlers
    clickPreviousMonth(e) {
        e.preventDefault();

        let previousMonth = this.state.currentMonth - 1;
        let year = this.state.currentYear;
        if (previousMonth === -1) {
            previousMonth = 11;
            year = year - 1;
        }

        const days = this.getDays(previousMonth, year, this.state.currentSchedule);
        this.setState({ currentMonth: previousMonth, currentYear: year, days: days });
    }

    clickNextMonth(e) {
        e.preventDefault();

        let nextMonth = this.state.currentMonth + 1;
        let year = this.state.currentYear;
        if (nextMonth === 12) {
            nextMonth = 0;
            year = year + 1;
        }

        const days = this.getDays(nextMonth, year, this.state.currentSchedule);
        this.setState({ currentMonth: nextMonth, currentYear: year, days: days });
    }

    clickDay(day) {
        this.props.schedules.forEach((schedule, index) => {
            if (scheduleService.getDay(schedule.startDate) === day.day && scheduleService.getMonth(schedule.startDate) === this.state.currentMonth && scheduleService.getYear(schedule.startDate) === this.state.currentYear) {
                const days = this.getDays(this.state.currentMonth, this.state.currentYear, schedule);
                this.setState({ days: days, currentSchedule: schedule }, this.props.selectSchedule(schedule));
            }
        })
    }

    // Component
    render() {
        const daysOfWeek = ["SUN","MON","TUE","WED","THU","FRI","SAT"];
        const currentMonthAndYear = new Date(this.state.currentYear, this.state.currentMonth).toLocaleDateString("en-US", {month: 'long', year: 'numeric'});;
        const days = this.state.days;

        const selectedDate = new Date(this.state.currentSchedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'long', day: 'numeric'});
        const selectedTime = `${new Date(this.state.currentSchedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - ${new Date(this.state.currentSchedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})}`;
        const selectedDuration = `${Math.abs(new Date(this.state.currentSchedule.endDate) - new Date(this.state.currentSchedule.startDate)) / 36e5} hours`;

        return (
            <div className="a4-calendar">
                <div className="a4-calendar-container">
                    <div className="a4-calendar-content">
                        <div className="a4-calendar-header">
                            <div className="a4-calendar-header-current-month">
                                <h3>{currentMonthAndYear}</h3>
                            </div>
                            <div className="a4-calendar-header-controls">
                                <div className="a4-calendar-header-controls-button -previous" onClick={this.clickPreviousMonth}>
                                    <svg className="a4-svgsprite">
                                        <PreviousButton stroke={bootstrapService.primaryColor} />
                                    </svg>
                                </div>
                                <div className="a4-calendar-header-controls-button" onClick={this.clickNextMonth}>
                                    <svg className="a4-svgsprite">
                                        <NextButton stroke={bootstrapService.primaryColor} />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="a4-calendar-days">
                            <div className="a4-calendar-days-row" style={{"paddingBottom": "5px"}}>
                            {
                                daysOfWeek.map((day, index) => {
                                    return <div key={index} className="a4-calendar-days-cell">
                                        <div className="a4-calendar-days-header">{day}</div>
                                    </div>
                                })
                            }
                            </div>
                            {
                                [...Array(6)].map((row, rowIndex) => {
                                    return <div key={rowIndex} className="a4-calendar-days-row">
                                    {
                                        days.slice(rowIndex * 7, (rowIndex+1)*7).map((day, dayIndex) => {
                                            const canClick = day.unselected || day.selected;
                                            return <div key={dayIndex} className={`a4-calendar-days-cell ${day.disabled ? "-disabled" : ""} ${day.unselected ? "-unselected" : ""} ${day.selected ? "-selected" : ""}`} onClick={canClick ? () => this.clickDay(day) : undefined}>
                                                <div className="a4-calendar-days-day">{day.day}</div>
                                            </div>
                                        })
                                    }
                                    </div>
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="a4-calendar-info">
                        <div className="a4-calendar-info-item">
                            <div className="a4-calendar-info-label">Date</div>
                            <div className="a4-calendar-info-text">{selectedDate}</div>
                        </div>
                        <div className="a4-calendar-info-item">
                            <div className="a4-calendar-info-label">Time</div>
                            <div className="a4-calendar-info-text">{selectedTime} ({scheduleService.getTimeZone()})</div>
                        </div>
                        <div className="a4-calendar-info-item">
                            <div className="a4-calendar-info-label">Duration</div>
                            <div className="a4-calendar-info-text -duration">
                                <svg className="a4-svgsprite">
                                    <Clock fill={bootstrapService.primaryColor} />
                                </svg>
                                {selectedDuration}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Calendar;