import gsap from 'gsap'

class FormService {

    // Submit Button
    showLoadingButton(form) {
        const button = form.querySelector(".a4-form-submit");
        button.setAttribute("disabled", true);
        button.classList.add("-loading");
    }
    
    hideLoadingButton(form) {
        const button = form.querySelector(".a4-form-submit");
        button.classList.remove("-loading");
        button.removeAttribute("disabled");
    }

    // Show/Hide Errors
    showInputError(form, name, error) {
        const inputs = form.querySelectorAll(".a4-input");
        for (const inputDiv of inputs) {
            const input = inputDiv.querySelector("input, textarea");
            if (input.name === name) {
                const span = inputDiv.querySelector("span");
                span.innerText = error;
                inputDiv.classList.add("-error");
                return;
            }
        }
    }
    
    showFormError(form, error) {
        const p = form.querySelector(".a4-form-error");
        p.innerText = error;
        gsap.to(p, {
            display: "block",
            duration: 0.2,
        }, 0);
    }
    
    hideAllErrors(form) {
        const inputs = form.querySelectorAll(".a4-input");
        for (const input of inputs) {
            input.classList.remove("-error");
        }
    
        const error = form.querySelector(".a4-form-error");
        if (error) {
            error.innerText = "";
            gsap.to(error, {
                display: "none",
                duration: 0.2,
            }, 0);
        }
    }
    
    clearAllInputs(form) {
        const inputs = form.querySelectorAll(".a4-input");
        for (const inputDiv of inputs) {
            const input = inputDiv.querySelector("input, textarea");
            input.value = "";
        }
    }
    
    // Error Validation
    validate(form) {
        let results = [];
    
        this.hideAllErrors(form);
    
        const inputs = form.querySelectorAll('.a4-input');
        inputs.forEach((input) => {
            if (!input.classList.contains("-disabled")) {
                const requiredOK = this.checkRequired(input);
                const nameOK = this.checkName(input);
                const emailOK = this.checkEmail(input);
                const passwordOK = this.checkPassword(input);
                const creditCardOK = this.checkCreditCard(input);
                const expMonthOK = this.checkExpirationMonth(input);
                const expYearOK = this.checkExpirationYear(input);
                const cvvOK = this.checkCvv(input);
                const numberOK = this.checkNumber(input);
        
                results.push(requiredOK && nameOK && emailOK && passwordOK && creditCardOK && expMonthOK && expYearOK && cvvOK && numberOK);
            }
            else {
                results.push(true);
            }
        });
    
        return results.every(i => i);
    }
    
    checkRequired(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.attributes.required : false) {
            if (control.value.trim() === "") {
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
    
    checkName(input) {
        const control = input.querySelector("input, textarea");
    
        if (control) {
            const name = control.getAttribute("name");
            const error = input.querySelector(".a4-input-error");

            if (name === "fullName") {
                error.innerText = "Enter your full name";

                if(control.value !== "" && (control.value.split(" ").length < 2 || control.value.split(" ")[1] === "")) {
                    error.innerText = "Full name must be at least 2 words";
                    input.classList.add("-error");
                    return false;
                }
                else if (control.value !== "" && control.value.length > 70) {
                    error.innerText = "Name is too long";
                    input.classList.add("-error");
                    return false;
                }
            }
            else if (name === "firstName" || name === "middleName" || name === "lastName") {
                error.innerText = "";

                if (control.value !== "" && control.value.length > 35) {
                    error.innerText = "Name is too long";
                    input.classList.add("-error");
                    return false;
                }
            }
        }
        return true;
    }
    
    checkEmail(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.getAttribute("name") === "email" : false) {
            const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const error = input.querySelector(".a4-input-error");
            error.innerText = "Enter a valid email";

            if (control.value !== "" && control.value.length > 256) {
                error.innerText = "Email is too long";
                input.classList.add("-error");
                return false;
            }
    
            if(control.value !== "" && !emailRegex.test(control.value.trim())) {
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
    
    checkPassword(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.getAttribute("name") === "password" || control.getAttribute("name") === "confirm" : false) {
            const error = input.querySelector(".a4-input-error");
            error.innerText = "Enter a valid password";

            if (control.value !== "" && control.value.length > 128) {
                error.innerText = "Password is too long";
                input.classList.add("-error");
                return false;
            }
    
            if(control.value !== "" && control.value.length < 6) {
                error.innerText = "Password must be at least 6 characters"
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
    
    checkCreditCard(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.getAttribute("name") === "card" : false) {
            const error = input.querySelector(".a4-input-error");
            error.innerText = "Enter a valid credit card";
    
            if(control.value !== "" && !this.isValidCreditCardNo(control.value)) {
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
    
    isValidCreditCardNo(value) {
        // accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(value)) return false;
        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0, bEven = false; // , nDigit = 0
        value = value.replace(/\D/g, "");
        for (var n = value.length - 1; n >= 0; n--) {
            let cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
            if ((nDigit *= 2) > 9) nDigit -= 9;
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        if ((nCheck % 10) === 0) {
            if (value.length === 15 && (value.startsWith('34') || value.startsWith('37'))) {
            //amex
            return true;
            }
            else if ((value.length === 13 || value.length === 16) && value.startsWith('4')) {
            //visa
            return true;
            }
            else if ((value.length === 16) && (value.startsWith('51') || value.startsWith('52') || value.startsWith('53') || value.startsWith('54') || value.startsWith('55'))) {
            //mastercard
            return true;
            }
            else if ((value.length === 16 && value.startsWith('6011')) || (value.length === 15 && value.startsWith('5'))) {
            //discover
            return true;
            }
        }
        return false;
    }
    
    checkExpirationMonth(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.getAttribute("name") === "mm" : false) {
            if(control.value !== "" && (isNaN(control.value) || (control.value < 1 || control.value > 12))) {
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
    
    checkExpirationYear(input) {
        const control = input.querySelector("input, textarea");
        if (control ? control.getAttribute("name") === "yyyy" : false) {
            if(control.value !== "" && (isNaN(control.value) || control.value.length !== 4 || (control.value < new Date().getFullYear()))) {
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
    
    checkCvv(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.getAttribute("name") === "cvv" : false) {
            if(control.value !== "" && (isNaN(control.value) || (control.value.length < 3 || control.value.length > 4))) {
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }

    checkNumber(input) {
        const control = input.querySelector("input, textarea");
    
        if (control ? control.getAttribute("name") === "numberOfMembers" : false) {
            const error = input.querySelector(".a4-input-error");
            error.innerText = "Enter the number of members";

            if(control.value !== "" && isNaN(control.value)) {
                error.innerText = "Enter a valid number";
                input.classList.add("-error");
                return false;
            }
        }
        return true;
    }
}

const formService = new FormService();
export default formService;